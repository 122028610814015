<template>
  <div class="board-container center">
    <div class="board">
    <board-summary id="notice-board" :NOTICE_LIST="NOTICE_LIST" />
    <auto-slider id="auto-slider"/>
    </div>
  </div>
</template>

<script>
import BoardSummary from '../../components/common/BoardSummary'
import AutoSlider from '../../components/common/AutoSlider'

export default {
  props : ['NOTICE_LIST'],
  components : {
    BoardSummary,
    AutoSlider,
  }
}
</script>

<style>

</style>