<template>
  <div class="sub-banner-container center">
    <div class="sub-banner"
    :style="subBannerItemStyle">

      <!-- sub banner list -->
      <div class="sub-banner-items-container">
        <div class="sub-banner-items row" :style="subBannerStyle">
          <div class="sub-banner-item web"
          v-for="(item, index) in SUB_BANNER_WEB"
          :key="'sub-banner-web-' + index"
          :style="[subBannerItemStyle, {background : 'url(' + item.src + ')'}]"/>
          <div class="sub-banner-item mobile"
          v-for="(item, index) in SUB_BANNER_MOBILE"
          :key="'sub-banner-mobile-' + index"
          :style="[subBannerItemStyle, {background : 'url(' + item.src + ')'}]"/>
        </div>
      </div>

      <!-- sub banner pagination -->
      <div class="sub-banner-pagination-container">
        <span class="sub-banner-pagination"
        v-for="(item, index) in SUB_BANNER_WEB.length"
        :key="'sub-banner-pagination-'+ index"
        :class="{active : findCurrentIndex(index)}"/>
      </div>

      <!-- sub banner btn -->
      <div class="sub-banner-btn-container">
        <button class="sub-banner-btn left"
        v-show="currentIndex != 0"
        @click="onPrevious()">
          <img :src="paginationBtnLeftIcon" />
        </button>

        <button class="sub-banner-btn right"
        v-show="currentIndex != SUB_BANNER_WEB.length-1"
        @click="onNext()">
          <img :src="paginationBtnRightIcon" />
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import paginationBtnLeftIcon from '../../assets/icons/pagination_btn_left_icon.svg';
import paginationBtnRightIcon from '../../assets/icons/pagination_btn_right_icon.svg';
import { mapState } from 'vuex'
export default {

  // ! 추후 resize event 통합사용
  created() {
    // ? init settings
    this.onResize()
    
    window.addEventListener('resize', () => {
      this.onResize()
    })
  },

  unmounted() {
    window.removeEventListener('resize', () => {
      this.onResize()
    })
  },

  data() {
    return {
      currentIndex : 0,
      scrollX : 0,
      innerWidth : 0,

      // ? pagination icon
      paginationBtnLeftIcon,
      paginationBtnRightIcon,
    }
  },

  computed : {

    ...mapState([
      'SUB_BANNER_WEB', 'SUB_BANNER_MOBILE'
    ]),

      // ? sub banner style
    subBannerStyle() {
      let innerWidth = this.innerWidth > 768 ? 1060 : this.innerWidth

      let length = this.SUB_BANNER_WEB.length
      let scrollX = this.scrollX

      return {
        width : (length * innerWidth) + 'px',
        transform : 'translate(-' + scrollX + 'px, 0px)'
      }
    },

    subBannerItemStyle() {
      let innerWidth = this.innerWidth > 768 ? 1060 : this.innerWidth
      return {
        'max-width' : innerWidth + 'px',
        'min-width' : innerWidth + 'px',
      }
    }
  },

  methods : {

    // ? find current index in sub banner list
    findCurrentIndex(num) {
      if(this.currentIndex == num) return true
      else return false
    },

    onNext() {
      this.currentIndex += 1;
      this.scrollToNext()
    },

    onPrevious() {
      this.currentIndex -= 1;
      this.scrollToPrevious()
    },

    scrollToNext() {
      this.scrollX += this.innerWidth
    },

    scrollToPrevious() {
      this.scrollX -= this.innerWidth
    },

    onResize() {
      let innerWidth = window.innerWidth
      if(innerWidth <= 768) {
        this.innerWidth = innerWidth
        this.scrollX = 0
      } else {
        this.innerWidth = 1060
        this.scrollX = 0

        // ! 추후 수정, <768에서 >768으로 이동할 경우 scrollLeft 고정되는 증상
        let scrollLeft = document.querySelector('.sub-banner-items-container')
        if(scrollLeft) document.querySelector('.sub-banner-items-container').scrollLeft = 0
      }
    }

  }
}
</script>

<style>

</style>