<template>
<div class="table-container column">
  <span>
    <p class="table-title">현재 모집과정 보기</p>
  </span>
    <hr class="table-hr">
    <div class="table">
      <!-- 추후 관리자 화면에서 수정가능하도록 반영 -->
      <table class="table"  id="curriculum">
        <!-- table header -->
        <tr class="table-header">
          <th class="table-header-title">과정명</th>
          <th class="table-header-period">교육기간</th>
          <th class="table-header-time">교육시간</th>
          <th class="table-header-link">지원하기</th>
        </tr>

        <!-- table content -->
        <tr class="table-content"
        v-for="(item, index) in CLASS_LIST"
        :key="'lecture-item-'+index">
          <td>{{item.title}}</td>
          <td>
            <p v-for="(period, periodIndex) in item.period" :key="'lecture-item-'+index+'period-'+periodIndex">{{changeDateFormat(period.start)}} ~ {{changeDateFormat(period.end)}}({{period.date}})</p>
          </td>
          <td>
            <p v-for="(time, timeIndex) in item.time" :key="'lecture-item-'+index+'time-'+timeIndex">{{time}}</p>
          </td>
          <td>
            <a class="table-content-link" :href="item.link">지원 링크</a>
          </td>
        </tr>

      </table>
    </div>
</div>
  
</template>

<script>
import moment from 'moment';

export default {
  props : ['CLASS_LIST'],

  methods : {
        changeDateFormat(date) {
        const d = new Date(date)
        return moment(d).format('YY-MM-DD')
    },
  }
}
</script>

<style>

</style>
