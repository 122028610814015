<template>
<div class="auto-slider-container">
    <p class="board-title">관련 사이트</p>
    <div class="auto-slider">
        <div class="auto-slider-items row"
        :style="{transform : 'translate(-' + scrollX + 'px, 0px)'}">
        <a class="auto-slider-item"
        v-for="(item, index) in RELATED_ITEMS"
        :href="item.link"
        :key="'auto-slider-'+index">
            <img class="auto-slider-item-image" :src="item.icon">
            <p class="auto-slider-item-title">{{item.title}}</p>
        </a>
        </div>
    </div>
</div>
</template>

<script>
import hrdKoreaIcon from '../../assets/images/related_sites/hrd_korea_icon.png'
import keisIcon from '../../assets/images/related_sites/keis_icon.png'
import comwelIcon from '../../assets/images/related_sites/comwel_icon.jpg'
import moelIcon from '../../assets/images/related_sites/moel_icon.png'
import qnetIcon from '../../assets/images/related_sites/qnet_icon.png'
import hrdNetIcon from '../../assets/images/related_sites/hrd_net_icon.png'
import worknetIcon from '../../assets/images/related_sites/worknet_icon.png'

export default {

    mounted() {
        if(this.interval != null) return
        this.interval = setInterval( () => {
            this.scrollX = this.scrollX + 140
            this.scrollX = this.scrollX > 860 ? 0 : this.scrollX
        }, 3000)
    },

    unmounted() {
        clearInterval(this.interval)
    },

    data () {
        return {
            interval : null,
            scrollX : 0,

            RELATED_ITEMS : [
                {title : '한국산업인력공단', icon : hrdKoreaIcon, link : 'https://www.hrdkorea.or.kr'},
                {title : '한국고용정보원', icon : keisIcon, link : 'http://www.keis.or.kr/main/index.do'},
                {title : '근로복지공단', icon : comwelIcon, link : 'https://www.comwel.or.kr/comwel/main.jsp'},
                {title : '고용노동부', icon : moelIcon, link : 'http://www.moel.go.kr/index.do'},
                {title : 'Q-Net', icon : qnetIcon, link : 'http://www.q-net.or.kr/man001.do?imYn=Y&gSite=Q'},
                {title : 'HRD-Net', icon : hrdNetIcon, link : 'https://www.hrd.go.kr/hrdp/ma/pmmao/indexNew.do'},
                {title : 'Worknet', icon : worknetIcon, link : 'https://www.work.go.kr/index.jsp'},
            ]
        }
    },
}
</script>

<style>

</style>