<template>
<div class="popup-container" v-if="this.POPUP">

<div class="popup-image-container">
    <img :src="popup"/>
</div>

<!-- <div class="popup-btn-container">
<a class="popup-btn left" href="/comm/notice/632058761c2fab102dbad8db">
    <p>재창업과정 소개</p>
</a>
<a class="popup-btn right" style="width:100%!important;" href="http://pf.kakao.com/_MDgaK" target="_blank">
    <img class="popup-icon right" :src="kakaotalk">
    <p>카카오톡 상담</p>
</a>
</div>

<div class="popup-close-container">
    <span class="popup-close-today-container">
        <p class="popup-close-today-text">오늘 하루동안 닫기</p>
        <input class="popup-close-today-check" type="checkbox" v-model="onCloseToday"/>
    </span>
    <p class="popup-close" @click="onClose()">닫기 X</p>
</div>
-->

<div class="popup-btn-container">
<a class="popup-btn left" href="/uploads/2025%EB%85%84%20%EC%9D%BC%EB%B0%98%EA%B3%A0%20%EC%A0%9C%EC%B6%9C%EC%84%9C%EB%A5%98%20%EB%AA%A8%EC%9D%8C.hwp">
    <img class="popup-icon left" :src="download">
    <p>구비서류 다운</p>
</a>
<a class="popup-btn right" href="http://pf.kakao.com/_MDgaK" target="_blank">
    <img class="popup-icon right" :src="kakaotalk">
    <p>카카오톡 상담</p>
</a>
</div>

<div class="popup-close-container">
    <span class="popup-close-today-container">
        <p class="popup-close-today-text">오늘 하루동안 닫기</p>
        <input class="popup-close-today-check" type="checkbox" v-model="onCloseToday"/>
    </span>
    <p class="popup-close" @click="onClose()">닫기 X</p>
</div>


</div>
</template>

<script>

import kakaotalk from '../../assets/icons/kakaotalk_icon.svg';
import download from '../../assets/icons/download_icon.svg';
import popup from '../../assets/popup/popup.jpeg';

import { mapState, mapMutations } from 'vuex';

export default {

    mounted() {
        this.SET_POPUP();
    },

    data() {
        return {
            kakaotalk,
            download,

            popup,

            onCloseToday: false,
        }
    },

    computed: {
        ...mapState([
            'POPUP'
        ])
    },

    methods: {

        ...mapMutations([
            'SET_POPUP'
        ]),
        
        onClose() {
		this.SET_POPUP(false)
		if(this.onCloseToday == true) this.$cookies.set('popup', false)
	},
    }
}
</script>

<style>
.popup-container {
    position: absolute;
    top:10%;
    left:10%;
    z-index: 9999;
    background-color: #fae100;
}

.popup-image-container {
    background-color: #fae100;
}

.popup-image {
}

.popup-btn-container {
    display: flex;
}

.popup-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px 16px;

    width: 50%;

}

.popup-btn.left {
}

.popup-btn.right {
}

.popup-icon {
    margin-right: 8px;
}

.popup-icon.left {
    
}

.popup-icon.right {
    
}

.popup-close-container {
    display: flex;
    justify-content: space-between;
    background: black;

    color:white;
}

.popup-close-today-container {
    display: flex;
    padding: 4px 8px;
}

.popup-close {
    padding: 4px 8px;
    cursor: pointer;
}


.popup-close-today-text {
    margin-right: 8px;
}

.popup-close-today-check {
    cursor: pointer;

}

</style>
