<template>
  <div class="main-banner-container">
      <div class="main-banner web" :style="{
        background : 'url(' + MAIN_BANNER_WEB[0].src + ') center top / cover no-repeat'
      }" />
      <div class="main-banner mobile" :style="{
        background : 'url(' + MAIN_BANNER_MOBILE[0].src + ') center top / cover no-repeat'
      }" />
  </div>
</template>

<script>

export default {
    props : [
      'MAIN_BANNER_WEB', 'MAIN_BANNER_MOBILE'
    ],
}
</script>

<style>
</style>