<template>
  <div class="board-summary-container">

      <span class="row space-between">
        <p class="board-title">공지사항</p>
        <a class="board-more default-margin" href="/comm/notice">more</a>
        
      </span>

      <hr>

      <table class="board-summary">
        <tr class="board-summary-header">
            <th class="board-summary-title">제목</th>
            <th class="board-summary-writer">작성자</th>
            <th class="board-summary-date">날짜</th>
            <th class="board-summary-view">조회수</th>
        </tr>

        <tr class="board-summary-content cursor"
        v-for="(item, index) in NOTICE_LIST"
        :key="'board-summary-'+index"
        @click="onPost(item._id)">
            <td class="board-summary-title">{{item.title}}</td>
            <td class="board-summary-writer">{{item.writer}}</td>
            <td class="board-summary-date">{{changeDateFormat(item.created_at)}}</td>
            <td class="board-summary-view">{{item.view}}</td>
        </tr>

      </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment';

export default {

    computed: {
        ...mapState([
            'NOTICE_LIST'
        ])
    },

    methods  :{
        changeDateFormat(date) {
            const d = new Date(date)
            return moment(d).format('YY-MM-DD')
        },

        onPost(id) {
            this.$router.push('/comm/notice/'+id)
        }
    }

}
</script>

<style>

</style>