<template>
  <div class="slider column" :id="ID">
    <div class="slider-items row"
    :style="sliderStyle">

      <div class="slider-item-container"
      v-for="(item, index) in CLASS_BANNER"
      :key="'slider-item-'+index">
        <div class="slider-item-info">
          <p class="slider-item-title">{{item.title}}</p>
          <p class="slider-item-content">{{item.content}}</p>
        </div>

        <div class="slider-item-background"/>
        <div class="slider-item-image"         
        :style="{
        background : 'url(' + item.src + ') center top / cover no-repeat'
        }" />

        <div class="slider-item-btn-container cursor">
          <a class="slider-item-btn-content row" :href="item.link">
            <div class="slider-item-btn-text default-margin">더 보러가기</div>
            <img class="slider-item-btn-icon" :src="arrowForwardIcon"/>
          </a>
        </div>
      </div>     
    </div>

      <!-- slider pagination btn -->
      <div class="slider-pagination-container">
        <button class="slider-pagination-btn left"
        v-show="currentIndex != 0"
        @click="onPrevious()">
          <img :src="paginationBtnLeftIcon" />
        </button>

        <button class="slider-pagination-btn right"
        v-show="currentIndex != CLASS_BANNER.length-3"
        @click="onNext()">
          <img :src="paginationBtnRightIcon" />
        </button>
      </div>

  </div>
</template>

<script>
import arrowForwardIcon from '../../assets/icons/arrow_forward_icon.svg'
import paginationBtnLeftIcon from '../../assets/icons/pagination_btn_left_icon.svg';
import paginationBtnRightIcon from '../../assets/icons/pagination_btn_right_icon.svg';

export default {
  props : ['ID', 'CLASS_BANNER'],

  // ! 추후 resize event 통합사용
  created() {
    // ? init settings
    this.onResize()
    
    window.addEventListener('resize', () => {
      this.onResize()
    })
  },

  unmounted() {
    window.removeEventListener('resize', () => {
      this.onResize()
    })
  },

  data() {
    return {
      currentIndex : 0,
      scrollX : 0,
      innerWidth : 370,

      // ? pagination icon
      arrowForwardIcon,
      paginationBtnLeftIcon,
      paginationBtnRightIcon,
    }
  },

  computed : {
    sliderStyle() {
      let length = this.CLASS_BANNER.length
      let width = this.innerWidth * length
      let scrollX = this.scrollX
      return {
        transform : 'translate(-' + scrollX + 'px, 0px)',
        width : width + 'px',
      } 
    }
  },

  methods : {
    onNext() {
      this.currentIndex += 1;
      this.scrollToNext();
    },

    onPrevious() {
      this.currentIndex -= 1;
      this.scrollToPrevious();
    },

    scrollToNext() {
      this.scrollX += this.innerWidth
    },

    scrollToPrevious() {
      this.scrollX -= this.innerWidth
    },

    onResize() {
      let innerWidth = window.innerWidth
      if(innerWidth <= 768) {
        this.currentIndex = 0
        this.scrollX = 0
      } else {
        this.currentIndex = 0
        this.scrollX = 0
      }

        // ! 추후 수정, <768에서 >768으로 이동할 경우 scrollLeft 고정되는 증상
        let scrollLeft = document.querySelector('#'+this.ID)
        if(scrollLeft) document.querySelector('#'+this.ID).scrollLeft = 0

    }

  }
}
</script>

<style>

</style>