<template>
  <div class="overlay-banner-container center cursor">
    <div class="overlay-banner">
      <a class="overlay-banner-item center class"
      href="#curriculum">
        <!-- board container 생성 후 연결 -->
        <p>현재 모집과정 보기</p>
      </a>
      <a class="overlay-banner-item center consult cursor" href="http://pf.kakao.com/_MDgaK" target="_blank">
        <img class="popup-icon right" :src="kakaotalk"/>
	<p>카카오톡 상담</p>
      </a>
      <span class="overlay-banner-item column center contact">
        <p>고객상담센터 주말·공휴일도 상담 및 접수가능</p>
        <p class="center"><img class="default-margin" :src="phoneIcon"/>031-255-8586</p>
      </span>
    </div>
  </div>
</template>

<script>
import phoneIcon from '../../assets/icons/phone_icon.svg';
import kakaotalk from '../../assets/icons/kakaotalk_icon.svg';

export default {
  data() {
    return {
      phoneIcon,
      kakaotalk
    }
  },
}
</script>

<style>

</style>
