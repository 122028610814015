<template>
  <div class="class-container column">

    <!-- ID 부여 -->
    <div class="slider-container column">
        <p class="slider-title">자격증 클래스</p>
      <Slider :ID="'gov-cert'" :CLASS_BANNER="CLASS_BANNER_CERTIFICATE" />
    </div>

    <!-- ID 부여 -->
    <div class="slider-container column">
      <p class="slider-title">특화과정 클래스</p>
      <Slider :ID="'gov-spcl'" :CLASS_BANNER="CLASS_BANNER_SPECIAL" />
    </div>

  
  </div>
</template>

<script>
import Slider from '../common/Slider';

export default {
  props : [
    'CLASS_BANNER_SPECIAL', 'CLASS_BANNER_CERTIFICATE'
  ],

  components : {
    Slider,
  }
}
</script>

<style>

</style>